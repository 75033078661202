import React from 'react';

import Layout from 'components/Layout';
import Container from 'components/Container';
import blackDiamonds from "../../images/black-diamonds.svg";
import {Link} from "gatsby";
import Seo from "../../components/Seo";
import qa1 from "../../images/qa/qa-august2021.png";
import qa2 from "../../images/qa/qa-march2021.png";
import qa3 from "../../images/qa/qa-sept2021.png";
import qa4 from "../../images/qa/qa4.png";
import qa5 from "../../images/qa/ai-mayjune.jpg";
import qa6 from "../../images/qa/cannabis-marchapriil.jpg";
import qa7 from "../../images/qa/cleaning-mayjune.jpg";
import qa8 from "../../images/qa/cleaning2-mayjune.jpg";
import qa10 from "../../images/qa/fsmapetfood-marchapril.jpg";
import qa11 from "../../images/qa/fsmahumanfood-marchapril.jpg";
import qa12 from "../../images/qa/pestmgmt-septoct.jpg";
import qa13 from "../../images/qa/labmanagement.jpg";

const QualityPage = () => {
    return (
            <Layout pageName="quality-assurance">
                <Seo
                        title={'Quality Assurance Magazine'}
                />
                <Container>
                    <div className="grid grid-cols-1 gap-10">
                        <div className="grid md:grid-cols-6 md:gap-10 content-center md:mb-16">
                            <img src={qa3} alt="quality assurance magazine" className="md:col-span-3 w-full"/>
                            <div className="text-left md:col-span-3 lg:col-span-2 mt-4 md:mt-0">
                                <h2 className="text-7xl font-serif">Quality Assurance</h2>
                                <h3 className="font-mono text-xl mt-4">2021 — Present</h3>
                                <img src={blackDiamonds} alt="" className="mt-6"/>
                                <p className="mt-8">In January 2021, I took over as art director of Quality Assurance magazine, which focuses on the food quality and safety industry.</p>
                                <p className="mt-4">My task, alongside the new editor, was to rebrand the publication’s market identity in print and online, as well as redesign the entire magazine from front to back.</p>
                                <p className="mt-4">Below are samples that showcase how a B2B publication — limited in staff and budget — can create interesting, effective storytelling that commands attention.</p>
                                <p className="mt-4">And I challenge <strong>anyone</strong> to show me a better rodent-themed homage to “A Night at the Roxbury” than in “The Mouse Pad.”</p>
                            </div>
                        </div>
                        <img src={qa5} alt="" className="max-w-full" />
                        <img src={qa6} alt="" className="max-w-full" />
                        <div className="grid md:grid-cols-2 gap-10">
                            <img src={qa1} alt="" className="max-w-full"/>
                            <img src={qa4} alt="" className="max-w-full"/>
                        </div>
                        <img src={qa12} alt="" className="max-w-full" />
                        <div className="grid md:grid-cols-2 gap-10">
                            <img src={qa2} alt="" className="max-w-full"/>
                            <img src={qa11} alt="" className="max-w-full"/>
                        </div>
                        <div className="grid md:grid-cols-2 gap-10">
                            <img src={qa10} alt="" className="max-w-full"/>
                            <img src={qa7} alt="" className="max-w-full"/>
                        </div>
                        <img src={qa8} alt="" className="max-w-full" />
                        <img src={qa13} alt="" className="max-w-full" />
                        <div className="md:mt-16">
                            <Link to="/work/misc" className="text-4xl font-serif hover:text-pink transition-colors duration-200">Next Project: Grab Bag</Link>
                        </div>
                    </div>
                </Container>
            </Layout>
    );
};

export default QualityPage;
